/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 100%;
  width: 1848px;
  box-sizing: border-box;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
}
.cb-home {
  margin: 47px auto;
}
.cb-page-layout1 .section--multimood,
.cb-page-layout4 .section--multimood {
  margin-top: 160px;
}
.northcontent {
  width: 48.97959184%;
  margin-left: 25.51020408%;
  margin-right: 25.51020408%;
}
#toplinks {
  margin: 25px 0;
}
.footpart {
  width: 30.6122449%;
}
.footpart.footpart--large {
  width: 32.65306122%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.area > .slim .part {
  width: 100%;
}
.area > .slim .tiny {
  width: 100%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0.60483871%;
  margin-left: 0.60483871%;
  width: 98.79032258%;
}
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim {
  width: 48.79032258%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.side > .slim .part {
  width: 100%;
}
.side > .slim .tiny {
  width: 100%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north .foot {
  margin-right: 3.125%;
  margin-left: 3.125%;
}
.north .part {
  margin-right: 3.125%;
  margin-left: 3.125%;
  width: 93.75%;
}
.north > .slim {
  width: 50%;
}
.north > .slim .foot,
.north > .slim .part {
  margin-right: 6.25%;
  margin-left: 6.25%;
}
.north > .slim .part {
  width: 87.5%;
}
.north > .slim .tiny {
  width: 87.5%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0.60483871%;
  margin-left: 0.60483871%;
  width: 98.79032258%;
}
.south .foot {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
}
.south .part {
  margin-right: 2.04081633%;
  margin-left: 2.04081633%;
  width: 95.91836735%;
}
.south > .slim {
  width: 32.12365591%;
}
.south > .slim .foot,
.south > .slim .part {
  margin-right: 6.27615063%;
  margin-left: 6.27615063%;
}
.south > .slim .part {
  width: 87.44769874%;
}
.south > .slim .tiny {
  width: 87.44769874%;
}
div.north {
  margin-left: -3.33333333%;
  width: 106.66666667%;
}
div.farnorth {
  width: 100%;
}
div.south {
  width: 101.2244898%;
  margin-left: -0.6122449%;
}
div.side {
  margin-left: -0.6122449%;
  width: 101.2244898%;
}
div.main div.unit.wide {
  width: 57.14285714%;
}
div.main div.unit.slim {
  width: 30.20408163%;
  margin-left: 6.32653061%;
  margin-right: 6.32653061%;
}
div.main div.seam.wide div.part,
div.main div.flat.wide div.part,
div.main div.edge.wide div.part {
  margin-left: 2.85714286%;
  margin-right: 2.85714286%;
  width: 94.28571429%;
}
div.main div.seam.wide div.part.tiny,
div.main div.flat.wide div.part.tiny,
div.main div.edge.wide div.part.tiny {
  width: 44.28571429%;
}
div.main div.seam.slim div.part,
div.main div.flat.slim div.part,
div.main div.edge.slim div.part {
  margin-left: 5.40540541%;
  margin-right: 5.40540541%;
  width: 89.18918919%;
}
div.south div.unit.wide {
  width: 48.38709677%;
  margin-left: 25.80645161%;
  margin-right: 25.80645161%;
}
div.south div.unit.wide div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */